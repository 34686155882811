import React from "react"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import "../scss/bootstrap-custom.scss"
import "../lib/animate-3.7.2.css"

const NotFoundPage = ({ location }) => {
  const { img } = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "photo/Nózi1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const title = "404: Nincs ilyen oldal"
  const subtitle = "Nincs ilyen oldal"

  return (
    <>
      <SEO location={location} title={title} description={subtitle} />

      <Container className="mt-5 mb-5 text-center">
        <Row>
          <Col>
            <Card>
              <Img
                className="card-img card-img-top"
                style={{ width: "100%" }}
                fluid={img.childImageSharp.fluid}
              />
              <Card.Body>
                <h2 className="animated fadeInUp">
                  404! Váúúúú... nincs ilyen oldal!
                </h2>
                <Button href="/">Vissza a honlapra!</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default NotFoundPage
